<template>
    <section class="project-form site-max-width" id="form">
        <div class="mobile-imgs hide-on-desktop" data-inview="revealRight" data-delay="200">
            <img src="@/assets/img/mobile-footer.jpg" alt="Projet" />
            <h3 class="big-title" data-inview="fadeInUp" data-delay="200">
                <span v-html="$t('Humanité, urbanité, <br /> durabilité.')"></span>
            </h3>
        </div>
        <div class="inner">
            <router-link :to="formLink" class="left left-link-box" data-prerender-link data-here-link-li>
                <img class="spacer" :src="formInfos && formInfos.lienImage ? formInfos.lienImage.url : ''" :alt="formInfos && formInfos.titre ? formInfos.titre : ''" />

                <div class="img-wrap" data-inview="revealLeft" data-delay="200">
                    <img :src="formInfos && formInfos.lienImage ? formInfos.lienImage.url : ''" :alt="formInfos && formInfos.titre ? formInfos.titre : ''" />

                    <div class="default-effect"></div>
                    <div class="hover-effect"></div>

                    <svg
                        class="arrow"
                        height="32"
                        viewBox="0 0 79 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M75.0234 26.0769L-8.76925e-06 26.0769L-8.67511e-06 23.9231L75.0234 23.9231L52.5646 1.50797L54.0685 -1.08979e-06L79 25L54.0685 50L52.5646 48.5993L75.0234 26.0769Z"
                            fill="black"
                        />
                    </svg>
                </div>

                <div class="over-link">
                    <span v-html="formTitle"></span>
                </div>
            </router-link>

            <div class="right">
                <Form />
            </div>
        </div>
    </section>
</template>

<script>
import Form from '@/components/Form'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'BottomForm',

    props: {
        formTitle: {
            type: String,
            required: true,
        },
        formLink: {
            type: String,
            required: true,
        },
    },

    components: {
        Form,
    },

    computed: {
        formInfos() {
            if (!this.globalsIsLoaded || !this.$route) {
                return {}
            }

            let indexOfForm = 'formulaireInscription'

            if (this.$route.name.includes('Projet')) {
                indexOfForm = 'formulaireVariantePageProjet'
            }

            if (this.$route.name.includes('Contact')) {
                indexOfForm = 'formulaireVariantePageContact'
            }

            return this.globals.footer[indexOfForm]
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
